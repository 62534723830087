import { useRef } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { TileImage } from '@components/Tiles/components/TileImage/TileImage';
import { TileLabels } from '@components/Tiles/components/TileLabels/TileLabels';
import { Coupon } from '@components/UI/Coupon/Coupon';
import { Alert } from '@components/UI';
import { time as TimeIcon } from '@assets/icons/system';
import { ProductTile as IProductTile } from '@commons/product';
import { getConfigurationOptions } from '@adapters/product/getProductTile';
import { GroupScale } from '@components/GroupScale/GroupScale';
import { TileQuantity } from '@components/Tiles/components/TileQuantity/TileQuantity';
import { TilePrice } from '@components/Tiles/components/TilePrice/TilePrice';
import { TileName } from '@components/Tiles/components/TileName/TileName';
import { useLocalization } from '@hooks/useLocalization';
import { useSelectItemEvent } from '@modules/ga/hooks/useSelectItemEvent';
import { isEligibleForAddingToShoppingList } from '@utils/isEligibleForAddingToShoppingList';
import { routing } from '@constants/routing';
import { TrackViewItemList } from '@modules/ga/events/ecommerce/view-item-list/TrackViewItemList';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { ProductListChannelProvider, useProductListChannel } from '@modules/ga/context/channel';
import { ProductListLocationProvider, useProductListLocation } from '@modules/ga/context/location';
import { useCriteoBeacon } from '@hooks/criteo/useCriteoBeacon';
import { sendBeacon } from '@hooks/criteo/useCriteoBeacon.utils';
import { AddToShoppingListButton } from '@components/AddToShoppingListButton/AddToShoppingListButton';
import { TOnAddToBag } from '@modules/ga/type';
import { useChooseProductForShoppingList } from '@hooks/shoppingLists/useChooseProductForShoppingList';
import { TileStandingOrderButton } from '@components/Tiles/components/TileStandingOrderButton/TileStandingOrderButton';
import { getBreadcrumbs } from '@modules/ga/utils/getBreadcrumbs';
import { CriteoFormatBeacon } from '@modules/criteo';
import { ProductTileQuantitySelector } from '../ProductTile/ProductTileQuantitySelector';
import styles from './ProductTileV2.module.scss';

export interface ProductTileProps {
  children?: never;
  className?: string;
  product: IProductTile;
  hideGroupScale?: boolean;
  isShowImage?: boolean;
  isReorder?: boolean;
  onAddToBag?: TOnAddToBag;
  onClick?: (productId?: string) => void;
  onClickCriteo?: () => void;
  nonFocusable?: boolean;
  freeItem?: boolean;
  hideShoppingListButton?: boolean;
  productCarouselType?: string;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  clippedCouponId?: string;
  onClipCouponEvent?: (couponId: string) => void;
  onChange?: () => void;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
}

export const ProductTileV2 = ({
  className,
  product,
  isShowImage = true,
  hideGroupScale = false,
  isReorder,
  onClickCriteo,
  onClick,
  nonFocusable,
  freeItem = false,
  hideShoppingListButton = false,
  productCarouselType,
  criteoFormatBeaconView,
  clippedCouponId,
  onClipCouponEvent,
  onChange,
  skipAddToCart,
  isReorderItem,
}: ProductTileProps) => {
  const router = useRouter();
  const { t } = useLocalization('productTile');
  const { chooseProduct } = useChooseProductForShoppingList();
  const { getListName } = usePageListName();
  const { getLocation } = useProductListLocation();
  const productRef = useRef<HTMLDivElement>(null);
  const shouldRenderGroupScale = () => !hideGroupScale && product.groupScale?.grpId;
  const shouldRenderCoupon = product.coupon?.couponId;
  const hasCouponOrGroupscaleOrPreparationTime = () =>
    shouldRenderGroupScale() || shouldRenderCoupon || product.preparationTime > 0;
  const { trackSelectItem } = useSelectItemEvent();
  const { fireRWProductClickEvent } = useProductListChannel();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const dataVariantProp = product.variantId ? { 'data-variant': product.variantId } : {};
  const listName = getListName(product);
  const { unitSize, unitPrice, price, pricePerScaleUnit, roughPricePerUnit, defaultScaleUnit } =
    product;

  const shouldRenderTileQuantity =
    (unitSize || unitPrice || pricePerScaleUnit || roughPricePerUnit || defaultScaleUnit) &&
    price.currency;

  const { handleClick } = useCriteoBeacon({
    onClickBeacon: product.clickBeacon,
    onLoadBeacon: product.imageBeacon,
    onViewBeacon: product.viewBeacon,
    componentRef: productRef,
    criteoFormatBeaconView,
  });

  const handleProductClick = (trackListName: string) => {
    if (product) {
      trackSelectItem(product, trackListName);
      fireRWProductClickEvent(product);
    }
    onClickCriteo?.();
    onClick?.();
    handleClick?.();
  };

  const getChannel = () => {
    if (product.marketingTags.sponsored) {
      return 'rec_criteo';
    }
  };

  const getProductLocation = () => {
    const pathname = router.asPath;
    if (product.marketingTags.sponsored && pathname.startsWith(routing.allSales)) {
      return 'cat_' + getBreadcrumbs().split('/').join('');
    }

    return getLocation();
  };

  const handleAddToShoppingList = () => {
    chooseProduct({
      product,
      configurationOptions: getConfigurationOptions(product.variations),
      salesUnit: product.salesUnits.find((e) => e.selected)?.alternateSalesUnit,
    });
    if (product?.clickBeacon) {
      sendBeacon(product.clickBeacon);
    }
  };

  return (
    <ProductListChannelProvider channel={getChannel()}>
      <ProductListLocationProvider location={getProductLocation()}>
        <TrackViewItemList productRef={productRef} product={product} listName={listName} />
        <div
          className={styles.product_card_wrapper}
          ref={productRef}
          data-ga-tile-list={getListName(product)}
          data-ga-tile-list-type="product-tile"
          data-ga-tile-id={product.productId}
          data-testid={`product-tile-${product.productId}`}
        >
          <div
            // product_tile_card class is used by GTM
            className={`product_tile_card ${cx(styles.product_card, className)}`}
            {...dataVariantProp}
          >
            <div className={styles.product_card_main_info}>
              <div className={styles.product_card_image_wrapper}>
                {product.productImage.ref && isShowImage && (
                  <TileImage
                    img={{
                      ref: product.productImage.ref,
                      alt: product.productImage.alt ?? t('ariaLabels.tileImage'),
                    }}
                    productUrl={product.productPageUrl}
                    hasWineData={product?.hasWineData}
                    onProductClick={handleProductClick}
                    expressEligible={product.featureTags.expressEligible}
                    peakQuality={product.featureTags.topPick}
                    isV2={true}
                  />
                )}
                <ProductTileQuantitySelector
                  className={styles.product_card_quantityselector}
                  product={product}
                  productCarouselType={productCarouselType}
                  isReorder={isReorder}
                  nonFocusable={nonFocusable}
                  freeItem={freeItem}
                  onChange={onChange}
                  isV2={true}
                  skipAddToCart={skipAddToCart}
                  isReorderItem={isReorderItem}
                />
                {!hideShoppingListButton && (
                  <AddToShoppingListButton
                    product={product}
                    classNames={styles.product_card_shoppling_list}
                    shouldRenderAddToShoppingListButton={isEligibleForAddingToShoppingList(product)}
                    handleAddToShoppingList={handleAddToShoppingList}
                    nonFocusable={nonFocusable}
                  />
                )}
              </div>
              <TileName
                name={product.productName}
                brand={product.brandName}
                url={product.productPageUrl}
                onProductClick={handleProductClick}
                nonFocusable={nonFocusable}
                isV2={true}
              />
            </div>
            <div className={styles.product_card_sub_info} data-testid={t('ariaLabels.productInfo')}>
              <div className={styles.product_card_price_block}>
                <TilePrice
                  formattedCurrentPrice={product.formattedCurrentPrice}
                  currentPrice={product.price}
                  scaleUnit={product.scaleUnit}
                  prevPrice={product?.wasPrice}
                  minValueToOrder={product.quantity.minQuantity}
                  savingString={product.savingString}
                  discount={product.deal}
                  freeItem={freeItem}
                  isV2={true}
                />
                {shouldRenderTileQuantity && (
                  <TileQuantity
                    unitPrice={unitPrice}
                    unitSize={unitSize}
                    currency={price.currency}
                    roughPricePerUnit={roughPricePerUnit}
                    defaultScaleUnit={defaultScaleUnit}
                    pricePerScaleUnit={pricePerScaleUnit}
                    isV2={true}
                  />
                )}
              </div>
              {hasCouponOrGroupscaleOrPreparationTime() && !nonFocusable && (
                <div
                  className={styles.product_card_promo_cntr}
                  data-testid={t('ariaLabels.productInfo')}
                  data-product-id={product.productId}
                >
                  {shouldRenderGroupScale() && (
                    <div
                      className={styles.product_card_promo}
                      aria-label={t('ariaLabels.groupScales')}
                    >
                      <GroupScale
                        {...product.groupScale}
                        grpPrices={product.grpPrices}
                        id={product.productId}
                        isV2={true}
                      />
                    </div>
                  )}

                  {shouldRenderCoupon && (
                    <div
                      className={styles.product_card_coupon}
                      aria-label={t('ariaLabels.groupScales')}
                    >
                      <Coupon
                        productId={product.productId}
                        title={product.coupon.description}
                        id={product.coupon.couponId}
                        isActive={product.coupon.isActive}
                        prompt={product.coupon.detailedDescription}
                        expirationDate={product.coupon.expirationDate}
                        clippedCouponId={clippedCouponId}
                        onClipCouponEvent={onClipCouponEvent}
                        fullWidth
                        isV2={true}
                      />
                    </div>
                  )}
                  {product.preparationTime > 0 && (
                    <TileLabels preparationTime={product.preparationTime} isV2={true} />
                  )}
                </div>
              )}
            </div>
            {product.availability?.length > 0 && (
              <Alert type="warning" hasShowIcon={false} isFullWidth className={styles.alert}>
                <div className={styles.wrapper}>
                  <TimeIcon className={styles.icon} />
                  {product.availability?.map((warning, index) => (
                    <div key={index}>{warning}</div>
                  ))}
                </div>
              </Alert>
            )}
            <TileLabels marketingTags={product.marketingTags} isV2={true} />
            {isShowImage && !freeItem && (
              <TileStandingOrderButton
                product={product}
                className={styles.standing_order_btn}
                isV2={true}
              />
            )}
          </div>
        </div>
      </ProductListLocationProvider>
    </ProductListChannelProvider>
  );
};

ProductTileV2.displayName = 'ProductTileV2';
