import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { topPick as TopPickIcon } from '@assets/icons/system';
import {
  DepartmentUnion,
  GlobalHeaderQuery,
  NavigationMenuItem,
  DropdownMenu,
  SuperDepartmentItem,
  DepartmentItem,
  CategoryData,
} from '@api';
import { GLOBAL_HEADER } from '@graphql/globalHeader/queries/globalHeader';
import { LeftNavNode } from '@commons/navigation';
import { useServiceType } from '@hooks/useServiceType';

const peakQualityNameFromResponse = 'Peak Quality';

export interface NavigationData {
  primaryNavigation: Array<NavigationMenuItem | null>;
  departments: Record<string, DepartmentUnion | null | undefined>;
  secondaryNavigation: Array<NavigationMenuItem | null>;
  additionalMenus: Array<DropdownMenu | null>;
  leftNavigation?: { id: string; name: string; url: string; children: LeftNavNode[] }[];
  loading?: boolean;
}

const getSuperDepartmentChildren = (
  superDepartment?: SuperDepartmentItem | null,
): LeftNavNode[] => {
  if (!superDepartment || !superDepartment.departments) {
    return [];
  }

  return superDepartment.departments.map((department) => {
    return {
      id: department?.id || '',
      name: department?.name || '',
      url: department?.url || '',
      children: getDepartmentCategories(department as DepartmentItem),
    };
  });
};

const getDepartmentCategories = (department?: DepartmentItem | null): LeftNavNode[] => {
  if (!department || !('dropDownCategories' in department) || !department.dropDownCategories) {
    return [];
  }

  return department.dropDownCategories.reduce((categories, ddc) => {
    if (!ddc || !ddc.categoryMap) {
      return categories;
    }

    let children: LeftNavNode[] = [];

    ddc.categoryMap.forEach((categoryMap) => {
      if (!categoryMap || !categoryMap.categories) {
        return;
      }

      children = children.concat(getCategories(categoryMap.categories));
    });

    return categories.concat(children);
  }, [] as LeftNavNode[]);
};

const getCategories = (categories: Array<CategoryData>): LeftNavNode[] => {
  return categories.map((category) => {
    return {
      id: category.id || '',
      name: category.name || '',
      url: category.url || '',
      children: getCategories(category.categories || []),
    };
  });
};

export const useNavigationData = (): NavigationData => {
  const { data, refetch, loading } = useQuery<GlobalHeaderQuery>(GLOBAL_HEADER);
  const { serviceType } = useServiceType();
  const departmentNavigation = data?.globalHeader?.departmentNavigation;
  const primaryNavigation = departmentNavigation?.primaryNavigation ?? [];
  const abstractNavigation = departmentNavigation?.abstractDepartments ?? [];
  const secondaryNavigation = (
    departmentNavigation?.secondaryNavigation ?? []
  ).map<NavigationMenuItem | null>((item) =>
    // TODO: think about getting icon from BE
    item?.name === peakQualityNameFromResponse ? { ...item, icon: TopPickIcon } : item,
  );
  const additionalMenus = departmentNavigation?.additionalMenus ?? [];

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 0);
  }, [serviceType]);

  const leftNavigation = departmentNavigation?.abstractDepartments?.map((node) => {
    let children: Array<LeftNavNode> = [];

    if (node && 'departments' in node) {
      children = children.concat(getSuperDepartmentChildren(node as SuperDepartmentItem));
    } else {
      children = children.concat(getDepartmentCategories(node as DepartmentItem));
    }

    return {
      id: node?.id || '',
      name: node?.name || '',
      url: node?.url || '',
      children: children || [],
    };
  });

  const departments = primaryNavigation.reduce(
    (departmentsMap, navItem) =>
      navItem?.id
        ? {
            ...departmentsMap,
            [navItem.id]: abstractNavigation.find(
              (abstractNavigationItem) => abstractNavigationItem?.id === navItem.id,
            ) as DepartmentUnion | null | undefined,
          }
        : departmentsMap,
    {} as NavigationData['departments'],
  );

  return {
    primaryNavigation,
    departments,
    secondaryNavigation,
    additionalMenus,
    leftNavigation,
    loading,
  };
};
