import { useEffect, useState } from 'react';
import { isClient } from '../utils/isClient';

export interface WindowSize {
  width: number;
  height: number;
}

export interface ScreenSizeProps {
  isMobile?: boolean;
  isTablet?: boolean;
  isLaptop?: boolean;
  isDesktop?: boolean;
  isSmallScreen?: boolean;
  isLargeTablet: boolean;
}

const initialScreenSizeState: ScreenSizeProps = {
  isMobile: false,
  isTablet: false,
  isLaptop: false,
  isDesktop: false,
  isSmallScreen: false,
  isLargeTablet: false,
};

const VIEWPOINTS = {
  XSMALL: 0,
  SMALL: 768,
  MID: 1024,
  LARGE: 1180,
  XLARGE: 1440,
};

export const useWindowSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSizeProps>(initialScreenSizeState);
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: isClient() ? window.innerWidth : 0,
    height: isClient() ? window.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize, { passive: true });

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const width = windowSize.width;

    if (width <= VIEWPOINTS.SMALL) {
      setScreenSize({ ...initialScreenSizeState, isMobile: true, isSmallScreen: true });
    }
    if (width > VIEWPOINTS.SMALL && width < VIEWPOINTS.MID) {
      setScreenSize({ ...initialScreenSizeState, isTablet: true, isSmallScreen: true });
    }
    if (width >= VIEWPOINTS.MID && width < VIEWPOINTS.LARGE) {
      setScreenSize({ ...initialScreenSizeState, isLargeTablet: true, isSmallScreen: true });
    }
    if (width >= VIEWPOINTS.LARGE && width < VIEWPOINTS.XLARGE) {
      setScreenSize({ ...initialScreenSizeState, isLaptop: true });
    }
    if (width >= VIEWPOINTS.XLARGE) {
      setScreenSize({ ...initialScreenSizeState, isDesktop: true });
    }
  }, [windowSize.width]);

  return {
    windowSize,
    screenSize,
  };
};
