import { DropdownMenu, NavigationMenuItem } from '@api';
import { LeftNavNode } from '@commons/navigation';
import { routing } from '@constants/routing';

export interface LeftNavProps {
  children: LeftNavNode[];
  isOpen: boolean;
  routerAsPath: string;
  isRoot?: boolean;
  title?: string;
}

export interface AdditionalLeftNavProps {
  additionalMenuItems: NavigationMenuItem[];
  routerAsPath: string;
}

export type LeftNavigationType =
  | {
      id: string;
      name: string;
      url: string;
      children: LeftNavNode[];
    }[]
  | undefined;

export const getLeftNavData = (leftNavigation: LeftNavigationType, routePath: string) => {
  const leftNavData = leftNavigation?.filter((navItem) => {
    return navItem?.id === routePath.split('/').filter(Boolean)?.[0];
  });

  if (leftNavData?.length !== 0) {
    return leftNavData;
  }

  return leftNavigation?.filter((navItem) => {
    return navItem?.url === routePath || routePath.includes(navItem?.url);
  });
};

export const fullyIncludes = (fullString: string, subString: string) => {
  const regex = new RegExp(`\\b${subString}\\b`);
  return regex.test(fullString);
};

export const getAllParentElements = (parentTag: string, currentlist?: HTMLElement | null) => {
  if (!currentlist) {
    return;
  }

  const parentLists = [];
  let currentElement = currentlist?.parentElement;

  while (currentElement) {
    if (currentElement.tagName.toLowerCase() === parentTag) {
      parentLists.push(currentElement);
    }
    currentElement = currentElement.parentElement;
  }

  return parentLists;
};

export const shouldLoadDealsSalesLeftNav = (routePath: string) => {
  const dealsSalesUrls = [
    routing.dealsAndSale,
    routing.freshDeals,
    routing.allSales,
    routing.coupons,
  ];

  return dealsSalesUrls.some((url) => routePath.includes(url));
};

export const shouldLoadAdditionalLeftNav = (
  additionalMenus: (DropdownMenu | null)[],
  routerAsPath: string,
) => {
  const additionalMenusLength = additionalMenus?.[0]?.menuItems?.filter(
    (item) => item?.link === routerAsPath,
  ).length;

  return additionalMenusLength && additionalMenusLength > 0;
};
