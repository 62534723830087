import { Shadows } from '@mui/material/styles/shadows';
import { match } from 'css-mediaquery';
import breakpoints from '../styles/breakpoints.module.scss';
import colors from '../styles/colors.module.scss';
import boxShadowsStyles from '../styles/shadows.module.scss';
import spacingStyles from '../styles/spacing.module.scss';
import zIndexes from '../styles/zIndex.module.scss';
import radiiStyles from '../styles/radius.module.scss';
import { createThemeWithTypography } from './typography';

export const customColorPalette = {
  primary: {
    primaryDarkest: colors.primaryDarkest,
    primaryDarker: colors.primaryDarker,
    primaryDark: colors.primaryDark,
    primary: colors.primary,
    primaryLight: colors.primaryLight,
    primaryLighter: colors.primaryLighter,
    primaryLightest: colors.primaryLightest,
  },
  gray: {
    black: colors.black,
    grayDarker: colors.grayDarker,
    grayDark: colors.grayDark,
    grayLight: colors.grayLight,
    grayLighter: colors.grayLighter,
    white: colors.white,
  },
  backgrounds: {
    gray4: colors.gray4,
    gray3: colors.gray3,
    gray2: colors.gray2,
    gray1: colors.gray1,
    deliveryPassAndExpressBG: colors.deliveryPassBG,
  },
  categorical: {
    topPick: colors.topPickPrimary,
    sale: colors.sale,
    deliveryPassAndExpress: colors.deliveryPassFG,
    deliveryPassAndExpressDarker: colors.deliveryPassDark,
  },
  warning: {
    warningBG: colors.warningBG,
    warningFG: colors.warningFG,
  },
  error: {
    errorBG: colors.errorBG,
    errorFG: colors.errorFG,
  },
  info: {
    infoBG: colors.infoBG,
    infoFG: colors.infoFG,
  },
  success: {
    successBG: colors.successBG,
    successFG: colors.successFG,
  },
  masquerade: {
    masqueradeGreen: colors.masqueradeGreen,
    masqueradeOrange: colors.masqueradeOrange,
  },
};

export type RadiiType = keyof typeof radii;

export const radii = radiiStyles;

export const boxShadows = boxShadowsStyles;

export const spacing = spacingStyles;

const themeShadows: { [key: number]: string } = {
  0: boxShadowsStyles['0'],
  2: boxShadowsStyles['2'],
  4: boxShadowsStyles['3'],
  16: boxShadowsStyles['5'],
};
const getShadows = (): Shadows => {
  return Array(25)
    .fill('none')
    .map((item: string, index: number) => {
      return themeShadows[index] || item;
    }) as Shadows;
};

const commonTheme = {
  spacing: Object.values(spacingStyles).map((spacingItem) =>
    spacingItem.startsWith('.') ? `0${spacingItem}rem` : `${spacingItem}rem`,
  ),
  shadows: getShadows(),
  breakpoints: {
    values: {
      xs: +breakpoints.xs,
      sm: +breakpoints.sm,
      largeTablet: +breakpoints.largeTablet,
      md: +breakpoints.lg,
      lg: +breakpoints.lg,
      xl: +breakpoints.xl,
    },
  },
  zIndex: {
    appBar: +zIndexes.appBar,
    drawer: +zIndexes.drawer,
    fab: +zIndexes.fab,
    mobileStepper: +zIndexes.mobileStepper,
    modal: +zIndexes.modal,
    snackbar: +zIndexes.snackbar,
    speedDial: +zIndexes.speedDial,
    tooltip: +zIndexes.tooltip,
    searchAutocomplete: +zIndexes.searchAutocomplete,
    draftIndicator: +zIndexes.draftIndicator,
  },
};

const ssrMatchMedia = (query: string) => ({
  matches: match(query, {
    width: 800, //It's an approximation of "average" screen size.
  }),
});

const theme = {
  ...commonTheme,
  components: {
    MuiUseMediaQuery: {
      defaultProps: { ssrMatchMedia },
    },
  },
  palette: {
    text: {
      primary: customColorPalette.gray.black,
    },
    primary: {
      main: customColorPalette.primary.primary,
      light: customColorPalette.primary.primaryLight,
      dark: customColorPalette.primary.primaryDark,
      contrastText: customColorPalette.primary.primary,
    },
    error: {
      main: customColorPalette.error.errorFG,
      light: customColorPalette.error.errorBG,
      contrastText: customColorPalette.error.errorFG,
    },
    warning: {
      main: customColorPalette.warning.warningFG,
      light: customColorPalette.warning.warningBG,
      contrastText: customColorPalette.warning.warningFG,
    },
    info: {
      main: customColorPalette.info.infoFG,
      light: customColorPalette.info.infoBG,
      contrastText: customColorPalette.info.infoFG,
    },
    success: {
      main: customColorPalette.success.successFG,
      light: customColorPalette.success.successBG,
      contrastText: customColorPalette.success.successFG,
    },
    deliveryPass: {
      main: customColorPalette.categorical.deliveryPassAndExpress,
      light: customColorPalette.backgrounds.deliveryPassAndExpressBG,
      contrastText: customColorPalette.categorical.deliveryPassAndExpress,
    },
    grey: {
      50: customColorPalette.backgrounds.gray1,
      gray1: customColorPalette.backgrounds.gray1,
      gray2: customColorPalette.backgrounds.gray2,
      gray3: customColorPalette.backgrounds.gray3,
      gray4: customColorPalette.backgrounds.gray4,
      grayDark: customColorPalette.gray.grayDark,
      grayDarker: customColorPalette.gray.grayDarker,
      grayLight: customColorPalette.gray.grayLight,
      grayLighter: customColorPalette.gray.grayLighter,
    },
    contrastThreshold: 0,
    tonalOffset: 0,
  },
};

export const defaultTheme = createThemeWithTypography(theme);
