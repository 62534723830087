import { cloneElement, ReactElement } from 'react';
import { DialogContent, Modal as ModalWrapper, Slide } from '@mui/material';
import cx from 'classnames';
import { FocusTrap } from '@mui/base';
import { IconButton } from '@components/UI';
import { arrowLeft as ArrowLeftIcon, close as CloseIcon } from '@assets/icons/system';
import { useAuthModalContext } from '@context/AuthModalContext';
import {
  freshDirectWhite as FreshDirectWhiteIcon,
  freshDirect as FreshDirectIcon,
} from '@assets/icons/logos';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { IAuthModalAction } from '@context/AuthModalContext/AuthModalContext';
import { DATA_QA } from '@constants/dataQA';
import styles from './Modal.module.scss';

export enum MODAL_TYPE {
  OUTLINED = 'Outlined',
  PRIMARY = 'Primary',
}

export enum MODAL_TITLE_ID {
  AUTH = 'auth_modal_title',
  TC_CHANGED = 'tc_changed_modal_title',
  FORGOT_PASSWORD = 'forgot_password_modal_title',
  CONTACT_US = 'contact_us_modal_title',
  UNLINK = 'unlink_modal_title',
  PASSWORD = 'password_modal_title',
  DELIVERY = 'delivery_modal_title',
  TIMESLOTS = 'timeslots_modal_title',
  RESERVATION_TIMESLOTS = 'reservation_timeslots_modal_title',
  WELCOME_CREATE_ACCOUNT = 'welcome_create_account_modal_title',
  WELCOME_SIGN_IN = 'welcome_sign_in_modal_title',
  PRODUCT_CONFIGURATION = 'product_configuration_modal_title',
  SHOPPING_LIST = 'shopping_list_modal_title',
  DELIVERY_AREA_CHANGE = 'delivery_area_change_modal_title',
  DELIVERY_PASS_ERROR = 'delivery_pass_error_modal_title',
  RESEND_GIFT_CARD = 'resend_giftcard_modal_title',
  MOBILE_PROMO = 'mobile_promo_modal_title',
  MODIFY_ORDER_ERROR = 'modify_order_error_modal_title',
  MODIFY_ORDER_POLLING = 'modify_order_polling_modal_title',
  PENDING_ORDER = 'pending_order_modal_title',
  TIPS_DETAIL = 'tips_detail_modal_title',
  EDIT_PAYMENT = 'edit_payment_modal_title',
  INELIGIBLE_PRODUCTS = 'ineligible_products_modal_title',
  REPLACE_PRODUCT = 'replace_modal_title',
  PAYMENT_DELETE = 'payment_delete_modal_title',
  DELIVERY_CARD_DELETE = 'delivery_card_delete_modal_title',
  AUTO_RENEWAL_DISABLE = 'auto_renewal_disable_modal_title',
  DELETE_RECEIVED_GIFTCARD = 'delete_received_giftcard_modal_title',
  CANCEL_NOTIFICATION = 'cancel_notification_modal_title',
  CLEAR_CART = 'clear_cart_modal_title',
  DELIVERY_PASS_MODAL = 'delivery_pass_modal_title',
  REMOVE_CONFIRMATION = 'remove_confirmation_modal_title',
  ALCOHOL_MODAL = 'alcohol_modal_title',
  EBT_AVAILABLE = 'ebt_available_modal_title',
  FRESH_BLOCK = 'fresh_blocl_modal_title',
  ORDER_INFO_MOBILE = 'order_info_mobile_modal_title',
  SUBMITTED_MODAL = 'submitted_modal_title',
  DELETE_SHOPPING_LIST = 'delete_shopping_list_modal_title',
  ADD_ALL_TO_CART = 'add_all_cart_modal_title',
  ADD_TO_SHOPPING_LIST = 'add_to_shopping_list_modal_title',
  COPY_TO_SHOPPING_LIST = 'copy_to_shopping_list_modal_title',
  DELETE_ACCOUNT = 'delete_account_modal_title',
  RECEIVE_SMS = 'receive_sms_modal_title',
  RESERVATION_INFO = 'reservation_info_modal_title',
  EXIT_MODAL = 'exit_modal_title',
  SO_PAYMENT_MODAL = 'so_payment_modal_title',
  SO_DELETE_MODAL = 'so_delete-modal_title',
  CREATE_GIFTCARD_MODAL = 'create_giftcard_modal_title',
  EDIT_GIFTCARD_MODAL = 'edit_giftcard_modal_title',
  ORDER_MODIFICATION_CONTAINER_MODAL = 'order_modification_modal_title',
  STANDING_ORDER_TIMESLOT_MODAL = 'standing_order_timeslot_modal_title',
  TIMESLOT_MODAL = 'timeslot_modal_title',
  EDIT_INSTRUCTION_MODAL = 'edit_instruction_modal_title',
  EMPTY_CART_MODAL = 'empty_cart_modal_title',
  SO_PANEL_MODAL = 'so_panel_modal_title',
  SO_PRODUCT_LIST_MODAL = 'so_product_list_modal_title',
  SO_SUCCESS_MODAL = 'so_success_modal_title',
  ADD_TO_SO_MODAL = 'add_to_so_modal_title',
  BULK_GIFT_CARD_MODAL = 'bulk_gift_card_modal_title',
  MANAGE_SHOPPING_LIST_MODAL = 'manage_shopping_list_modal_title',
  INFO_CONTENT_MODAL = 'info_content_modal_title',
  SIDE_BAG_MODAL_TITLE = 'side_bag_modal_title',
  TOP_RATED_QUALITY_MODAL_TITLE = 'top_rated_quality_modal_title',
  MAGIC_LINK_SENT_MODAL = 'magic_link_sent_modal_title',
  COUPON_INFO_MODAL = 'coupon_info_modal_title',
  NULL = '',
}

interface ModalProps {
  handleClose: (type?: IAuthModalAction) => void;
  open: boolean;
  requestCredit?: boolean;
  hideCloseButton?: boolean;
  isShowLogo?: boolean;
  isSlide?: boolean;
  handleBack?: () => void;
  children: ReactElement;
  type?: MODAL_TYPE;
  labelId: MODAL_TITLE_ID;
  wrapperClass?: string;
  childWrapperClass?: string;
  childDialogClass?: string;
  dataQA?: string;
}

interface SlideWrapperProps {
  children: ReactElement;
  isOpen: boolean;
  isSlide?: boolean;
}

const SlideWrapper = ({ children, isOpen, isSlide }: SlideWrapperProps) => {
  const { isTablet, isMobile, isLargeTablet } = useHeaderContext();
  const isRenderSlide = isSlide && (isTablet || isMobile || isLargeTablet);

  return (
    <FocusTrap open>
      {isRenderSlide ? (
        <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
          {children}
        </Slide>
      ) : (
        children
      )}
    </FocusTrap>
  );
};

export const Modal = ({
  open,
  handleClose,
  requestCredit = false,
  handleBack,
  children,
  isSlide,
  isShowLogo = true,
  type = MODAL_TYPE.OUTLINED,
  hideCloseButton,
  labelId,
  wrapperClass,
  childWrapperClass,
  childDialogClass,
  dataQA,
}: ModalProps) => {
  const { isLargeTablet, isLaptop, isSmallScreen } = useHeaderContext();
  const shouldRenderBackButton = !!handleBack && !!isSmallScreen;
  const { state: authModalState } = useAuthModalContext();
  const isPrimaryType = type === MODAL_TYPE.PRIMARY;
  const navigationsClasses = cx([styles.navigation], {
    [styles.request_credit_navigation]: requestCredit,
    [styles.primary]: isPrimaryType,
    [styles.hide_close_button]: hideCloseButton,
  });

  const getLogo = () => {
    if (!isShowLogo) return null;
    if (isPrimaryType && !isLaptop && isLargeTablet) {
      return (
        <FreshDirectWhiteIcon
          className={cx(styles.icon_top_navigation, {
            [styles.without_back_icon]: !handleBack,
          })}
        />
      );
    }
    return (
      <FreshDirectIcon
        className={cx(styles.icon_top_navigation, {
          [styles.without_back_icon]: !handleBack,
        })}
        aria-label="FreshDirect logo"
        role="img"
      />
    );
  };

  const onClose = () => {
    if (authModalState.isWelcomeModal) {
      handleClose({ type: 'welcomeClose' });
      return;
    }
    handleClose();
  };

  return (
    <ModalWrapper
      data-testid="modal"
      className={wrapperClass}
      open={open}
      onClose={onClose}
      aria-label="modal"
      disableAutoFocus
    >
      <DialogContent
        className={cx(styles.dialog_content, childDialogClass)}
        role="dialog"
        aria-labelledby={labelId}
        data-qa={dataQA ?? DATA_QA.MODAL}
      >
        <SlideWrapper isOpen={open} isSlide={isSlide}>
          <div
            className={cx(styles.wrapper, childWrapperClass, {
              [styles.primary]: isPrimaryType,
            })}
            tabIndex={-1}
          >
            <div className={navigationsClasses}>
              <div className={styles.logo_wrapper}>
                {shouldRenderBackButton && (
                  <IconButton
                    className={cx(styles.back_to_shop_button, {
                      [styles.primary]: isPrimaryType,
                    })}
                    onClick={handleBack}
                    aria-label={'back'}
                  >
                    <ArrowLeftIcon
                      className={cx(styles.back_to_shop_icon, {
                        [styles.primary]: isPrimaryType,
                      })}
                    />
                  </IconButton>
                )}
                {getLogo()}
              </div>
              {!hideCloseButton && (
                <IconButton
                  className={cx(styles.close_button, {
                    [styles.primary]: isPrimaryType,
                  })}
                  onClick={onClose}
                  aria-label={'close'}
                  variant="outlined"
                >
                  <CloseIcon
                    className={cx(styles.close_icon, {
                      [styles.primary]: isPrimaryType,
                    })}
                  />
                </IconButton>
              )}
            </div>
            <div aria-label="content" className={styles.content} tabIndex={-1}>
              {cloneElement(children, { handleClose })}
            </div>
          </div>
        </SlideWrapper>
      </DialogContent>
    </ModalWrapper>
  );
};
