export enum DYNAMIC_FEATURES {
  TEST = 'test',
  KNOCKING = 'knocking',
  REDIRECT_TO_APP_FEATURE = 'appRedirect',
  RECOMMENDED_BOTTOM_CAROUSEL = 'recommended',
  HIDE_ADD_PAYMENT = 'hideAddPayment',
  BANNERSERVICE = 'bannerService',

  // NOTE need to manually add values from '@modules/featureCheck/FeatureCheck.enums' since we cannot merge enums
  COS = 'newUXCOS',
  STANDING_ORDERS = 'standingOrder',
  EXPRESS_TOGGLE = 'expressToggleSwitch',
  REFER_A_FRIEND = 'referAFriend2024',
  LEFT_NAV = 'leftNavigation2024',
  NEW_PRODUCT_TILES = 'newProductTiles2024',
}

export interface DynamicFeature {
  name: DYNAMIC_FEATURES;
  enabled: boolean;
  options?: Record<string, string> | null;
}
