import { routing } from '@constants/routing';

export const dealsSubmenu = {
  id: 'deals',
  menuItems: [
    {
      link: routing.freshDeals,
      linkId: 'fresh_deals',
      name: 'Fresh Deals',
    },
    {
      link: routing.coupons,
      linkId: 'ecoupon',
      name: 'Coupons',
    },
    {
      link: routing.allSales,
      linkId: 'all_sale',
      name: 'Sale',
    },
  ],
};

export const dealsAnonSubmenu = {
  id: dealsSubmenu.id,
  menuItems: dealsSubmenu.menuItems.filter((item) => item.link !== routing.coupons),
};
