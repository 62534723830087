import cx from 'classnames';
import Trans from 'next-translate/Trans';
import { Typography } from '@mui/material';
import { CartLineControls } from '@components/Tiles/CartTile/components/Controllers/CartLineControls';
import { ProductTileLine } from '@components/Tiles/ProductTileLine/ProductTileLine';
import { ExpressIcon } from '@components/UI/ExpressIcon/ExpressIcon';
import { SingleStoreCartLine as SingleStoreCartLineType } from '@commons/cart';
import { useLocalization } from '@hooks/useLocalization';
import { useSelectItemEvent } from '@modules/ga/hooks/useSelectItemEvent';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { HandleReplaceParams } from '@context/ReplaceContext/ReplaceModalContext';
import styles from './SingleStoreCartLine.module.scss';

interface SingleStoreCartLineProps {
  className?: string;
  unavailableIcon?: JSX.Element;
  onReplace: (params: HandleReplaceParams) => void;
  onDelete: () => void;
  alcoholRestricted?: boolean;
  modifiedItem: boolean;
  cartLine: SingleStoreCartLineType;
  isExpressOnlyCartLine?: boolean;
  handleProductModal?: (cartLine: SingleStoreCartLineType, variant: MODAL_TYPE) => void;
}

export const SingleStoreCartLine = ({
  className,
  unavailableIcon,
  onReplace,
  onDelete,
  alcoholRestricted,
  modifiedItem,
  cartLine,
  isExpressOnlyCartLine,
  handleProductModal,
  ...props
}: SingleStoreCartLineProps) => {
  const { t } = useLocalization('components');
  const { trackSelectItem } = useSelectItemEvent();

  const alcoholDescription = () => {
    if (alcoholRestricted) {
      return t('cartTile.alcoholUnavailable');
    }
    if (isExpressOnlyCartLine) {
      return (
        <Trans
          i18nKey="components:expressOnlyCartLine.unavailable"
          components={[<ExpressIcon key="express" isInlineElement />, <strong key="strong" />]}
        />
      );
    }
    return t('nonExpressCartLine.unavailable');
  };

  const getAlternativeUrl = () => {
    const breadcrumbsLength = cartLine.breadcrumbs.length;
    if (breadcrumbsLength > 0) {
      return `${cartLine.breadcrumbs[breadcrumbsLength - 1].url}`;
    }
    return '';
  };

  const handleReplace = () => {
    onReplace({
      id: cartLine.id,
      url: getAlternativeUrl(),
      products: cartLine.recommendedProducts,
      selectedCartLine: cartLine,
      isNonExpressCartLine: true,
    });
  };

  const handleTileClick = () => {
    handleProductModal?.(cartLine, MODAL_TYPE.CART_MINI_PDP);

    if (cartLine?.product) {
      trackSelectItem(cartLine.product);
    }
  };

  return (
    <div {...props} className={cx(styles.wrapper, className)}>
      <ProductTileLine
        linkTarget="_blank"
        productPageUrl={cartLine.productPageUrl}
        productName={cartLine.description}
        img={{
          ref: cartLine.productImage,
          alt: cartLine.description,
          largeRef: '',
          thumbnailRef: '',
        }}
        isPeakQuality={false}
        isExpressEligible={false}
        isAvailable={false}
        isConfigurableProduct={false}
        onTileClick={handleTileClick}
        additionalInfo={
          <div
            className={cx(styles.unavailable_info_section, {
              [styles.gray]: isExpressOnlyCartLine,
            })}
          >
            <span className={styles.icon}>{unavailableIcon}</span>
            <Typography variant="caption" component="p" className={styles.description}>
              {alcoholDescription()}
            </Typography>
          </div>
        }
        controllers={
          <CartLineControls
            cartLineId={cartLine.id}
            quantitySelector={cartLine.quantitySelector}
            currentPrice={cartLine.displayPrice}
            previousPrice={cartLine.unscaledPrice}
            priceIndicators={cartLine.priceIndicators}
            configurable={false}
            onReplace={handleReplace}
            onDelete={onDelete}
            replaceable={cartLine.hasRecommendedReplacement}
            modified={modifiedItem}
            unavailable={true}
            isBundle={false}
          />
        }
      />
    </div>
  );
};
