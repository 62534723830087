import { useCartLineControlsState } from './hooks/useCartLineControlsState';
import { useChangeCartLineQuantity } from './hooks/cartHooks/useChangeCartLineQuantity';
import { useDeleteCartLine } from './hooks/cartHooks/useDeleteCartLine';
import { ControlsBaseProps } from './types';
import { ControlsContent } from './components/ControlsContent/ControlsContent';

export const CartLineControls = (props: ControlsBaseProps) => {
  const {
    cartLineId,
    quantitySelector: { quantity, minQuantity, maxQuantity },
    product,
    onDelete,
    inModifyMode,
    cartLineIndex,
    availableQuantity,
  } = props;
  const { currentQuantity, setCurrentQuantity } = useCartLineControlsState({
    defaultQuantity: quantity,
  });

  const { deleteItem, deleteInProgress } = useDeleteCartLine({
    cartLineId,
    inModifyMode: !!inModifyMode,
  });

  const { change, alert, resetAlert } = useChangeCartLineQuantity({
    productCouponId: product?.coupon.couponId ?? '',
    cartLineId,
    quantity: currentQuantity,
    maxQuantity,
    minQuantity,
    setQuantity: setCurrentQuantity,
    inModifyMode: !!inModifyMode,
  });

  const deleteHandler = async () => {
    await deleteItem();
    onDelete?.(cartLineIndex);
  };

  return (
    <ControlsContent
      {...props}
      deleteInProgress={deleteInProgress}
      currentQuantity={currentQuantity}
      alert={alert}
      handleDelete={deleteHandler}
      changeQuantity={change}
      resetAlert={resetAlert}
      availableQuantity={availableQuantity}
    />
  );
};
