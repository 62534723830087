import { gql } from '@apollo/client';

export const MINIMUM_ACCOUNT_PREFERENCES = gql`
  query MinimumAccountPreferences {
    accountPreferences {
      firstName
      lastName
      emailAddress
      serviceType
      phoneNumber
      linkedSocialProviders
      memberSinceYear
      isChefTableAvailable
      isEligibleForPreReservation
      isMasqueradeMode
      statusLevel
      otpLoginType
      isOtpLoginEnabled
      creditLimitInfo {
        amountAvailable
        isCreditLimitValid
      }
    }
  }
`;
