import { useState, useEffect } from 'react';
import cx from 'classnames';
import getConfig from 'next/config';
import { isDevMode } from '@helpers/dev.helpers';
import { useBanner, useBannerPreview, getBannerSequenceNumber } from '@hooks/useBanner';
import { Media, MEDIA_TYPE } from '@components/Media';
import { Pair, Banner as ApiBanner } from '@api';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getBannerEvent } from '@modules/ga/eventBodyGetters/bannerEvents';
import styles from './Banner.module.scss';

interface BannerProps {
  adBlockId: string;
  targeting?: Pair[];
  rotationPolicy?: ROTATION_POLICY;
}

export enum ROTATION_POLICY {
  SEQUENTIAL = 'SEQUENTIAL',
  RANDOM = 'RANDOM',
}

const { publicRuntimeConfig } = getConfig();
const BANNER_MEDIA_ROOT = publicRuntimeConfig.bannerMediaRoot ?? '/banners';

const trackBannerClick = (id: string, banner: ApiBanner) => {
  sendGtmEvent(getBannerEvent('banner_click', id, banner));
};

const trackBannerImpression = (id: string, banner: ApiBanner) => {
  sendGtmEvent(getBannerEvent('banner_view', id, banner));
};

const getImageBanner = ({ link, webCreative, mobileCreative, openInNewWindow }: ApiBanner) => {
  link = link || `/`;

  return (
    <a href={link} target={openInNewWindow ? '_blank' : ''}>
      {mobileCreative?.url ? (
        <picture>
          <source
            srcSet={`${BANNER_MEDIA_ROOT + mobileCreative.url}`}
            media="(max-width: 768px)"
            width={mobileCreative.width!}
            height={mobileCreative.height!}
          />
          <img
            src={`${BANNER_MEDIA_ROOT + webCreative!.url!}`}
            alt={webCreative!.altText ?? ''}
            width={webCreative!.width!}
            height={webCreative!.height!}
            fetchPriority="high"
          />
        </picture>
      ) : (
        /* eslint-disable-next-line @next/next/no-img-element */
        <img
          src={`${BANNER_MEDIA_ROOT + webCreative!.url!}`}
          alt={webCreative!.altText ?? ''}
          fetchPriority="high"
        />
      )}
    </a>
  );
};

const getTextImageBanner = ({ text, webCreative, mobileCreative }: ApiBanner) => {
  const bannerstyle = {
    /* eslint-disable @typescript-eslint/naming-convention */
    '--desktop-image': `url(${BANNER_MEDIA_ROOT + webCreative!.url!})`,
    '--mobile-image': 'var(--desktop-image)',
    '--desktop-width': `${webCreative!.width!}px`,
    '--mobile-width': 'var(--desktop-width)',
    '--desktop-aspect-ratio': `${webCreative!.width! / webCreative!.height!}`,
    '--mobile-aspect-ratio': 'var(--desktop-aspect-ratio)',
    /* eslint-enable @typescript-eslint/naming-convention */
  } as React.CSSProperties;

  if (mobileCreative?.url) {
    Object.assign(bannerstyle, {
      /* eslint-disable @typescript-eslint/naming-convention */
      '--mobile-image': `url(${BANNER_MEDIA_ROOT + mobileCreative.url})`,
      '--mobile-width': `${mobileCreative.width}px`,
      '--mobile-aspect-ratio': `${mobileCreative.width! / mobileCreative.height!}`,
      /* eslint-enable @typescript-eslint/naming-convention */
    } as React.CSSProperties);
  }

  return (
    <div className={cx(styles.ad_text, styles.ad_banner_text)} style={bannerstyle}>
      <Media content={text || ''} type={MEDIA_TYPE.MARKDOWN} />
    </div>
  );
};

const getTextBanner = ({ text }: ApiBanner) => {
  const type = text?.startsWith('<') ? MEDIA_TYPE.HTML : MEDIA_TYPE.MARKDOWN;
  return (
    <div className={styles.ad_text}>
      <Media content={text || ''} type={type} />
    </div>
  );
};

const getBannerFromList = (bannerList: ApiBanner[], rotationPolicy: ROTATION_POLICY) => {
  if (bannerList.length === 1) {
    return bannerList[0];
  }

  if (rotationPolicy === ROTATION_POLICY.RANDOM) {
    return bannerList[Math.floor(Math.random() * bannerList.length)];
  }

  // SEQUENTIAL
  return bannerList[getBannerSequenceNumber() % bannerList.length];
};

export const Banner = ({
  adBlockId,
  targeting,
  rotationPolicy = ROTATION_POLICY.SEQUENTIAL,
}: BannerProps) => {
  const { banners } = useBanner({ bannerIds: [adBlockId], targeting });
  const [forcedBanner, setForcedBanner] = useState<number | null>(null);
  const [reportedBanner, setReportedBanner] = useState<number | null>(null);
  const [devMode, setDevMode] = useState(false);
  const bannerList = banners.get(adBlockId);

  useEffect(() => {
    isDevMode({ cb: () => setDevMode(true) });
  }, [setDevMode]);

  let banner = null;
  if (bannerList && bannerList.length > 0) {
    banner =
      forcedBanner !== null
        ? bannerList[forcedBanner]
        : getBannerFromList(bannerList, rotationPolicy);
  }

  useEffect(() => {
    if (banner && reportedBanner !== banner.id) {
      trackBannerImpression(adBlockId, banner);
      setReportedBanner(banner.id!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner]);

  if (!banner || !bannerList) {
    return null;
  }

  let content = null;

  if (banner?.link && banner?.webCreative?.url) {
    content = getImageBanner(banner);
  } else if (banner?.webCreative?.url) {
    content = getTextImageBanner(banner);
  } else if (banner?.text) {
    content = getTextBanner(banner);
  }

  return content ? (
    <div
      className={styles.ad_container}
      id={`banner_${adBlockId}`}
      banner-id={adBlockId}
      dev-mark="ad"
      data-dev-note={'banner: ' + adBlockId}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div className={styles.ad_banner_content} onClick={() => trackBannerClick(adBlockId, banner)}>
        {content}
      </div>
      {devMode && bannerList.length > 1 && (
        <div className={styles.dev_banner_controls}>
          <button
            className={cx(styles.dev_banner_control, { [styles.active]: forcedBanner === null })}
            onClick={() => setForcedBanner(null)}
          >
            x
          </button>
          {bannerList.map((_, i) => (
            <button
              key={i}
              className={cx(styles.dev_banner_control, {
                [styles.active]: i === forcedBanner,
              })}
              onClick={() => setForcedBanner(i)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  ) : null;
};

export const BannerPreview = ({ id }: { id: string }) => {
  const banner = useBannerPreview({ id });
  let content = null;

  if (banner?.link && banner?.webCreative?.url) {
    content = getImageBanner(banner);
  } else if (banner?.webCreative?.url) {
    content = getTextImageBanner(banner);
  } else if (banner?.text) {
    content = getTextBanner(banner);
  }

  return content ? (
    <div className={styles.ad_container} id={`banner_preview_${id}`} banner-preview-id={id}>
      {content}
    </div>
  ) : null;
};
