import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import router from 'next/router';
import cx from 'classnames';
import { useLocalization } from '@hooks/useLocalization';
import { INVITE_FRIEND } from '@graphql/account/mutations/referAFriend';
import { Button } from '@components/UI/Button/Button';
import { InputButton } from '@components/UI/Inputs/InputButton/InputButton';
import { getPrice } from '@utils/getPrice';
import { ShareWidget } from '@components/ShareWidget/ShareWidget';
import { Media } from '@components/Media';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useAuthModalContext } from '@context/AuthModalContext';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { EMAIL_REGEX } from '@utils/validation';
import styles from './ReferAFriendHeader.module.scss';

interface ReferAFriendHeaderProps {
  referAFriendData?: {
    referAFriend: {
      enabled: boolean;
      referralUrl: string;
      balance: number;
    };
  };
  rafPromoData?: {
    promotionActivationThreshold: number;
    refererCredit?: number;
    promotionDiscount?: number;
  };
  inviteSuccessCallback?: () => void;
}

export const ReferAFriendHeader = ({
  referAFriendData,
  rafPromoData,
  inviteSuccessCallback,
}: ReferAFriendHeaderProps) => {
  const { t } = useLocalization('referAFriend');
  const { toggleAuthModal } = useAuthModalContext();
  const { isRecognizedUser } = useAuthContext();
  const [inputValue, setInputValue] = useState('');
  const [inpuError, setInputError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [inviteFriend, { data }] = useMutation(INVITE_FRIEND);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputError(false);
    setErrorText('');
    setInputValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateField()) {
      inviteFriend({
        variables: { emails: inputValue },
      });
      if (data?.sendReferralInvites?.success) {
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if (data?.sendReferralInvites?.success) {
      inviteSuccessCallback?.();
      setInputValue('');
    }
  }, [data?.sendReferralInvites?.success]);

  const mediaContext = {
    credit: '$' + (rafPromoData?.refererCredit || 50),
    currentCredit:
      referAFriendData?.referAFriend?.enabled &&
      getPrice(referAFriendData?.referAFriend?.balance, '$'),
    rafEnabled: referAFriendData?.referAFriend?.enabled,
  };

  const validateField = () => {
    const allSpacesRemoved = inputValue.replaceAll(' ', '');
    const emailAddresses = allSpacesRemoved.split(',');
    let errorString = '' + t('button.errorMessage');
    let hasError = false;
    emailAddresses.forEach((email) => {
      if (!EMAIL_REGEX.test(email)) {
        const separator = !hasError ? ': ' : ', ';
        errorString += separator + email;
        hasError = true;
      }
    });
    setErrorText(errorString);
    if (hasError) {
      setInputError(true);
    }
    return hasError;
  };

  return (
    <div className={styles.content}>
      <Media url="/media/editorial/site_pages/raf/raf_header.html" context={mediaContext}>
        <Typography className={styles.margin_bottom} variant="h2">
          {t('title')}
        </Typography>
      </Media>

      <div role="status">
        {data?.sendReferralInvites && (
          <p
            className={cx(
              { [styles.success]: data?.sendReferralInvites?.success },
              { [styles.error]: !data?.sendReferralInvites?.success },
            )}
          >
            {data.sendReferralInvites.message}
          </p>
        )}
      </div>
      {referAFriendData?.referAFriend && !referAFriendData?.referAFriend?.enabled && (
        <Button className={styles.start_shopping} onClick={() => router.push('/')}>
          {t('button.startShopping')}
        </Button>
      )}
      {referAFriendData && referAFriendData?.referAFriend?.enabled && (
        <>
          <form onSubmit={handleSubmit} noValidate>
            <InputButton
              value={inputValue}
              buttonName={t('button.name')}
              type="string"
              id="refer-a-friend-input"
              helperTextId="helper-text-refer-a-friend"
              helperText={t('button.helperText')}
              fullWidth
              onChange={handleChange}
              onClick={handleSubmit}
              label={t('button.label')}
              error={inpuError}
              errorText={inpuError ? errorText : undefined}
              onBlur={validateField}
              typeof="email"
              required
            />
          </form>

          <ShareWidget
            title={t('share.title', { promoAmount: rafPromoData?.promotionDiscount })}
            url={referAFriendData.referAFriend.referralUrl}
            label={t('share.label')}
          >
            {t('share.button')}
          </ShareWidget>
        </>
      )}

      {!referAFriendData?.referAFriend && (
        <>
          {isRecognizedUser ? (
            <Button
              className={styles.start_shopping}
              onClick={() => {
                toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
              }}
            >
              {t('button.signIn')}
            </Button>
          ) : (
            <Button
              className={styles.start_shopping}
              onClick={() => {
                toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT);
              }}
            >
              {t('button.createAccount')}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
