import cx from 'classnames';
import { adBlockDetect } from '@utils/adBlockDetect';
import styles from '../DevTools.module.scss';

export const AdBlockDetect = () => {
  return (
    <>
      {/* @ts-expect-error details support is lacking */}
      <details name="devtab">
        <summary>AdBlock detection</summary>
        <ul className={styles.runtimeconfig}>
          {Object.entries(adBlockDetect()).map(([key, value]) => (
            <li key={key} className={cx({ [styles.tracker_blocked]: !value })}>
              {key}: {value ? '✅' : '🚫'}
            </li>
          ))}
        </ul>
      </details>
    </>
  );
};
