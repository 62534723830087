import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';

export const FULL_FEATURES_LIST = [
  FEATURE.COS,
  FEATURE.STANDING_ORDERS,
  FEATURE.EXPRESS_TOGGLE,
  FEATURE.REFER_A_FRIEND,
  FEATURE.NEW_PRODUCT_TILES,
  FEATURE.LEFT_NAV,
];
