import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useUserDetails } from '@hooks/account/useUserDetails';
import { useRefererInfo } from '@hooks/referAFriend/useRefererInfo';
import { useValidateReferer } from '@hooks/referAFriend/useValidateReferer';
import { useLocalization } from '@hooks/index';
import { useNavigationData } from '@hooks/header/useNavigationData';
import { useReferralPromotionData } from '@hooks/referAFriend/useReferralPromotionData';
import { BottomLine } from '../BottomLine/BottomLine';
import { TopLine } from '../TopLine/TopLine';
import styles from './NavigationData.module.scss';

interface NavigationDataProps {
  bottomLineHidden: boolean;
}

export const NavigationData = ({ bottomLineHidden }: NavigationDataProps) => {
  const { t } = useLocalization('header');
  const navigationItems = useNavigationData();
  const { refererInfo, setRefererInfo, removeRefererInfo } = useRefererInfo();
  const [hasInitReferInfo, setHasInitReferInfo] = useState(false);
  const bottomLineClasses = cx(styles.wrapper_bottom_line, {
    [styles.hidden]: bottomLineHidden,
  });
  const { data: userDetails, loading: loadingUseerDetails } = useUserDetails();
  const referralFlag = !loadingUseerDetails && userDetails?.displayRafBanner;
  const [skipReferralQuery, setSkipReferralQuery] = useState(true);

  const saveRefererInfoFromURL = () => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const refererName = urlParams.get('referer');
      const refererInvite = urlParams.get('invite');

      if (refererName && refererInvite) {
        return { refererName, refererInvite };
      }
    }
  };

  const urlRefer = saveRefererInfoFromURL();
  const referInfo = urlRefer ?? refererInfo;

  const {
    data: validatedData,
    refetch: fetchRefererInfo,
    loading,
  } = useValidateReferer(referInfo.refererName, referInfo.refererInvite);

  const { data: rafPromoData, refetch: fetchRafPromoData } =
    useReferralPromotionData(skipReferralQuery);

  const validatedDataRef = useRef(null);

  useEffect(() => {
    if (
      !loading &&
      ((referInfo.refererInvite && referInfo.refererName) || referralFlag) &&
      !validatedDataRef.current &&
      !hasInitReferInfo
    ) {
      setHasInitReferInfo(true);
      fetchRefererInfo().then((res) => {
        if (res?.data?.validateReferralPromotion?.valid) {
          setRefererInfo(referInfo);
          validatedDataRef.current = validatedData;
          setSkipReferralQuery(false);
        } else {
          removeRefererInfo();
        }

        if (typeof window !== 'undefined' && urlRefer) {
          const url = new URL(window.location.href);
          url.searchParams.delete('referer');
          url.searchParams.delete('invite');
          window.history.replaceState({}, document.title, url.toString());
        }
      });
    }
  }, [
    fetchRefererInfo,
    referInfo,
    loading,
    hasInitReferInfo,
    removeRefererInfo,
    setRefererInfo,
    validatedData,
    referralFlag,
    urlRefer,
  ]);

  useEffect(() => {
    if (!skipReferralQuery) {
      fetchRafPromoData();
    }
  }, [skipReferralQuery, fetchRafPromoData]);

  const shouldShowReferralText =
    (validatedData?.validateReferralPromotion.valid &&
      referInfo?.refererName &&
      rafPromoData?.referralPromotionData?.promotionActivationThreshold) ||
    referralFlag;

  return (
    navigationItems && (
      <>
        {shouldShowReferralText && (
          <div className={styles.refer_text}>
            {t('referAFriend', {
              name: validatedData?.validateReferralPromotion.refererName,
              threshold: rafPromoData?.referralPromotionData?.promotionActivationThreshold,
              discount: rafPromoData?.referralPromotionData.promotionDiscount,
            })}
          </div>
        )}

        <TopLine navigationData={navigationItems} />
        <BottomLine className={bottomLineClasses} navigationData={navigationItems} />
      </>
    )
  );
};
