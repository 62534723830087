import { Typography } from '@mui/material';
import { Alert, Checkbox } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import styles from './KeepOrReplaceModalAlert.module.scss';

interface KeepOrReplaceModalAlertProps {
  checked: boolean;
  toggleCheckbox: () => void;
  availableQuantity: number;
}

export const KeepOrReplaceModalAlert = ({
  checked,
  toggleCheckbox,
  availableQuantity,
}: KeepOrReplaceModalAlertProps) => {
  const { t } = useLocalization();

  return (
    <Alert type="info" size="medium" hasShowIcon isFullWidth>
      <div className={styles.alert_content}>
        <div className={styles.text_container}>
          <Typography className={styles.alert_text} component="p" variant="body">
            {t('cart:replacementPrompt')}
          </Typography>
          <Typography className={styles.alert_text} component="p" variant="body">
            {t('cart:keepQuantityAndAddItems')}
          </Typography>
        </div>
        <label htmlFor="keep-or-replace-checkbox" className={styles.checkbox}>
          <Checkbox id="keep-or-replace-checkbox" onChange={toggleCheckbox} isChecked={checked} />
          {t('cart:keepQuantityCheckboxText', { quantity: availableQuantity })}
        </label>
      </div>
    </Alert>
  );
};
