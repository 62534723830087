import { gql } from '@apollo/client';
import { CREATIVE_FRAGMENT } from '@graphql/fragments/common/creativeFragment';

export const GET_BANNERS = gql`
  query GetBanners($bannerIds: [String!]!, $targeting: String!) {
    bannerSlots(input: { bannerSlots: $bannerIds, queryString: $targeting }) {
      banners {
        key
        value {
          id
          name
          text
          link
          campaignName
          program
          openInNewWindow
          webCreative {
            ...creativeFragment
          }
          mobileCreative {
            ...creativeFragment
          }
        }
      }
    }
  }
  ${CREATIVE_FRAGMENT}
`;

export const GET_BANNER_BY_ID = gql`
  query PreviewBanner($id: String!) {
    previewBanner(input: $id) {
      name
      text
      link
      program
      webCreative {
        ...creativeFragment
      }
      mobileCreative {
        ...creativeFragment
      }
    }
  }
  ${CREATIVE_FRAGMENT}
`;
